import * as React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { Link } from "gatsby";
import Layout from "../../components/layout/layout";
import TestimonialSlider from "../../components/testimonial-slider/testimonial-slider";

import "./about-us.scss";

const AboutPage = () => {
  return (
    <Layout>
      <>
        <section
          className="sec-common-page-heading"
          style={{ backgroundImage: "url('../images/laughing-doctors.jpg')" }}
        >
          <Container>
            <div className="d-flex justify-content-between align-items-center">
              <h2>About Us</h2>
              <Breadcrumb>
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">About</li>
              </Breadcrumb>
            </div>
          </Container>
        </section>

        <section className="sec-about">
          <Container>
            <Row>
              <Col lg="6">
                <div className="about-content">
                  <div className="title">About Us</div>
                  {/* <h3>Lorem Ipsum is simply dummy text</h3> */}

                  <p>
                    FHS is a 14-year-old international healthcare recruitment
                    and staffing agency based out of Wolverhampton. Our company
                    was established in 2009 with a vision to provide quality
                    medical staff, qualified nurses, and healthcare assistants
                    to NHS and private hospitals, residential care, nursing
                    homes, dementia and mental health establishments.
                  </p>
                  <p>
                    At FHS, we understand the growing demand for healthcare
                    professionals in the UK and the challenges faced by
                    healthcare establishments in recruiting and retaining
                    qualified staff. Therefore, we have created a comprehensive
                    range of services to support our clients in recruiting the
                    best talent from around the world. Our services encompass
                    job matching, visa support, cultural orientation,
                    accommodation support, financial planning, and ongoing
                    support to international medical candidates moving to the
                    UK.
                  </p>

                  <p>
                    Over the years, FHS has built a strong reputation in the
                    healthcare recruitment industry for our quality service,
                    professionalism, and commitment to our clients and
                    candidates. We have developed long-term partnerships with
                    many clients and placed thousands of healthcare
                    professionals in the UK.
                  </p>
                </div>
              </Col>
              <Col lg="6">
                <div className="img-wrap">
                  <img
                    style={{ marginTop: "30px" }}
                    className="d-block w-100 "
                    src="../images/laughing-doctors.jpg"
                    alt="First slide"
                  />
                </div>
              </Col>
              <Col>
                <p classsName="about-content-last">
                  We pride ourselves on our ability to offer a personalised
                  service to both our clients and candidates. Our dedicated team
                  of experienced recruiters work closely with our clients to
                  understand their unique requirements and provide them with the
                  best candidates to meet their needs. Similarly, we offer
                  tailored support to our candidates to help them settle into
                  their new roles in the UK, including assistance with visa
                  applications, cultural orientation, and financial planning.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="sec-about-value">
          <Container>
            <div className="about-content">
              <div className="title mb-3">Values</div>

              <ul>
                <li>
                  <i className="bi bi-check-lg"></i> We will take personal
                  responsibility for all our actions and omissions, we will
                  encourage feedback and share in the positive and negative.
                </li>
                <li>
                  <i className="bi bi-check-lg"></i> We will ensure that all
                  staff are accountable for their actions.
                </li>
                <li>
                  <i className="bi bi-check-lg"></i> We will seek continual
                  enhancement of a quality service through annual reviews.
                </li>
                <li>
                  <i className="bi bi-check-lg"></i> We will work openly and
                  promote loyalty and trust in ourselves and contracting
                  partners.
                </li>
                <li>
                  <i className="bi bi-check-lg"></i> We will show respect to
                  others and promote inclusiveness, tolerance and diversity and
                  actively encourage learning and development in ourselves and
                  others.
                </li>
              </ul>
            </div>
          </Container>
        </section>

        {/* <section className="sec-testimonial">
                    <div className="background-overlay" style={{
                        backgroundImage: `url('../images/dotted-map.png')`
                    }}></div>
                    <Container>
                        <div className="testimonial-content">
                            <div className="title">Our happy Patients</div>
                            <h3>Lorem Ipsum is simply dummy text</h3>
                        </div>
                        <Row>
                            <Col lg={{ span:8, offset:2 }}>
                                <TestimonialSlider />
                            </Col>
                        </Row>
                    </Container>
                </section> */}
      </>
    </Layout>
  );
};

export default AboutPage;
